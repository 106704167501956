.ph {
  @apply rounded-sm overflow-hidden select-none pointer-events-none;

  background-color: var(--st-color-grey);
  color: transparent !important;

  .ph {
    background-color: var(--st-color-light-grey);
  }

  &.circle {
    @apply rounded-full flex-none;
  }

  --ph-font-size: 1rem;
  --ph-line-height: 1.25rem;

  &.text {
    height: var(--ph-font-size);
    margin-top: calc((var(--ph-line-height) - var(--ph-font-size)) / 2);
    margin-bottom: calc((var(--ph-line-height) - var(--ph-font-size)) / 2);
  }

  &.h1 {
    --ph-font-size: 40px;
    --ph-line-height: 49px;

    @media (max-width: 767px) {
      --ph-font-size: 28px;
      --ph-line-height: 34px;
    }

    @media (max-width: 599px) {
      --ph-font-size: 20px;
      --ph-line-height: 24px;
    }
  }

  &.h2 {
    --ph-font-size: 32px;
    --ph-line-height: 39px;

    @media (max-width: 1440px) {
      --ph-font-size: 24px;
      --ph-line-height: 26px;
    }

    @media (max-width: 767px) {
      --ph-font-size: 24px;
      --ph-line-height: 29px;
    }

    @media (max-width: 599px) {
      --ph-font-size: 18px;
      --ph-line-height: 22px;
    }
  }

  &.h3 {
    --ph-font-size: 24px;
    --ph-line-height: 26px;

    @media (max-width: 767px) {
      --ph-font-size: 20px;
      --ph-line-height: 22px;
    }

    @media (max-width: 599px) {
      --ph-font-size: 18px;
      --ph-line-height: 22px;
    }
  }

  &.h4 {
    --ph-font-size: 20px;
    --ph-line-height: 22px;

    @media (max-width: 1199px) {
      --ph-font-size: 18px;
    }

    @media (max-width: 767px) {
      --ph-font-size: 18px;
      --ph-line-height: 20px;
    }

    @media (max-width: 599px) {
      --ph-font-size: 16px;
      --ph-line-height: 19px;
    }
  }

  &.p1 {
    --ph-font-size: 16px;
    --ph-line-height: 22px;

    @media (max-width: 599px) {
      --ph-font-size: 14px;
      --ph-line-height: 20px;
    }
  }

  &.p2 {
    --ph-font-size: 14px;
    --ph-line-height: 20px;

    @media (max-width: 599px) {
      --ph-font-size: 12px;
      --ph-line-height: 16px;
    }
  }
}

.shimmer {
  --shimmer-width: 200px;
  --shimmer-offset: 0px;
  --shimmer-half-width: calc(var(--shimmer-width) / 2);
  --shimmer-animation-start: 0ms;
  --shimmer-animation-duration: 1.5s;
  --shimmer-start: calc(0px - var(--shimmer-width) - var(--shimmer-offset));
  --shimmer-end: calc(200vw - var(--shimmer-width) - var(--shimmer-offset));

  position: relative;

  &::before {
    content: '';
    background-image: linear-gradient(90deg, rgb(255 255 255 / 0%) 0, rgb(255 255 255 / 40%) var(--shimmer-half-width), rgb(255 255 255 / 0%) var(--shimmer-width));
    position: absolute;
    background-repeat: repeat-x;
    top: 0;
    left: var(--shimmer-start);
    width: var(--shimmer-width);
    height: 100%;
    animation: bg-shimmer var(--shimmer-animation-duration) infinite linear;
    animation-delay: var(--shimmer-animation-start);
  }
}


@keyframes bg-shimmer {
  0% {
    left: var(--shimmer-start);
  }

  100% {
    left: var(--shimmer-end);
  }
}
