.card {
  @apply bg-white shadow-1 macbook:shadow-0 rounded-sm;

  @media (hover: hover) {
    @apply hover:shadow-1;
  }

  &.clickable {
    @apply cursor-pointer;

    &:active {
      @apply shadow-0;

      transform: scale(0.99) translateY(2px);
    }
  }


  transition: all .2s ease-in-out;
}
