@import "style/uikit/theme";
@import "style/utils";
@import "style/stretchit-font";
@import "@angular/cdk/overlay-prebuilt.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

html {
  height: 100%;
}

.st-container {
  @apply mx-auto macbook:container w-full px-4;

  @media (min-width: 1200px) {
    width: 1200px;
  }
}

.new-landing-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 920px) {
    width: 920px;
  }

  @media (min-width: 1200px) {
    width: 1200px;
  }

  @media (min-width: 1441px) {
    width: 1441px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.st-error {
  color: var(--st-color-red);
  overflow: hidden;
  text-overflow: ellipsis;
}

.st-input-wrapper,
.input-wrapper {
  position: relative;

  span {
    font-weight: 300;
  }

  &.has-error {
    input {
      border-color: var(--st-color-red);
      color: var(--st-color-red);
    }
  }
}

.without-padding {
  padding: 0 !important;
}

.banner-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 20px;

    &__short {
      width: 310px;
    }
  }
}

.banner-text {
  font-size: 16px;
  line-height: 22px;
  max-width: 381px;
  display: inline-block;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
}

app-challenge-details {
  .details .description p,
  .details .description ul {
    margin-bottom: 15px;
  }

  .details .description ul {
    margin-left: 15px;
  }

  .details .description li {
    margin-bottom: 8px;
    list-style-type: disc;
  }

  .details .description strong,
  .details .description b {
    font-weight: 500;
  }
}

.banner-text {
  b {
    font-weight: 500;
  }
}

body {
  font-family: var(--st-font-primary);
  min-width: 360px;
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  font-size: 16px;
  line-height: 1.2;
  color: var(--st-color-black);

  &.quiz {
    height: 100%;
    overflow-y: hidden;

    @media (max-width: 1050px) {
      overflow: inherit;
      overflow-x: hidden;
      height: auto;
    }
  }

  &.quiz-3 {
    height: 100%;

    @media (max-width: 1050px) {
      overflow: inherit;
      overflow-x: hidden;
    }
  }
}


.unbreakable {
  white-space: nowrap;
}

.highlighted {
  color: $blue;
}

.sale-text {
  margin-bottom: 16px;
}

.sale {
  .highlighted {
    color: $special;
  }
}

.price-font {
  font-size: 26px;
  font-family: Oswald;
  font-weight: 500;
  color: red;
  line-height: 55px;
  letter-spacing: 1px;
}

.grey-pop-up-font {
  color: #232323;
  opacity: 0.6;
}

.muted {
  color: $grey1;
}

video.success {
  width: 200px;
  height: 170px;
  margin: 0 auto;
}

.form-title {
  @extend %oswald-font;
  @extend %medium;

  font-size: 34px;
  color: $blue-dark;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 65px;
  text-transform: uppercase;

  @media screen and (min-width: 992px) {
    font-size: 42px;
  }
}

.or {
  position: relative;
  display: inline-block;
  margin: 24px auto;
  font-size: 14px;
  line-height: 18px;
  color: $grey3;
  letter-spacing: 0;
  padding: 0 70px;

  &.full {
    display: block;
    width: 100%;
  }

  &::before {
    content: " ";
    position: absolute;
    height: 1px;
    width: 50px;
    background: $grey3;
    left: 0;
    top: 50%;
  }

  &::after {
    content: " ";
    position: absolute;
    height: 1px;
    width: 50px;
    background: $grey3;
    right: 0;
    top: 50%;
  }
}

.input-block {
  position: relative;

  label {
    @extend %proxima-nova-font;

    display: block;
    font-size: 10px;
    color: $black;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  & > input,
  .input {
    @extend %proxima-nova-font;
    @extend %medium;

    display: block;
    width: 100%;
    background: $white;
    border: 1px solid $grey3;
    border-radius: 5px;
    font-size: 20px;
    letter-spacing: 0;
    padding: 9px 20px;
    outline: none;
    margin-bottom: 7px;

    &::placeholder {
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
      color: $grey3;
      letter-spacing: 0;
      line-height: 19px;
    }

    &:focus {
      border: 1px solid $black;
      z-index: 1;
    }

    &:last-child {
      margin-bottom: 22px;
    }

    &.placeholder {
      color: $placeholder;
    }
  }

  .input {
    & > input {
      outline: none;
      border: 0;
    }
  }

  & > button {
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 30px;
    border: 0;
    background: $black;
    font-size: 14px;
    color: $white;
    letter-spacing: 1px;
    outline: none;

    &:disabled {
      background: $grey2;
    }
  }

  show-hide-checkbox {
    position: absolute;
    top: 36px;
    right: 14px;
  }

  p {
    margin-bottom: 22px;
  }
}

.input-group {
  .input-block {
    & > input,
    .input {
      position: relative;
    }

    &:first-child {
      & > input,
      .input {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {
      & > input,
      .input {
        border-radius: 0 5px 5px 0;
      }

      margin-left: -1px;
    }
  }
}

.btn-login {
  @extend %proxima-nova-font;
  @extend %bold;

  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  margin-top: 40px;
  border-radius: 5px;
  background: $black;
  color: $white;
  border: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $grey4;
    background: $blue !important;
    border-color: $blue !important;
    box-shadow: 0 1px 15px 0 lightgrey;
  }
}

.btn-forgot {
  @extend %proxima-nova-font;

  cursor: pointer;
  font-size: 16px;
  color: $grey3;
  letter-spacing: 0;
  line-height: 26px;
}

.section-title {
  @extend %oswald-font;
  @extend %medium;

  font-size: 42px;
  color: $blue-dark;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 55px !important;
  margin-bottom: 37px;
}

.grey-line {
  background: $grey5;
}

.play-button {
  background: transparent;
  border: none;
  padding: 0;
  outline: none;

  @extend %bold;

  font-size: 14px;
  color: $blue-dark;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  icon {
    padding: 10px 7px 10px 12px;
    background: $blue-dark;
    color: $white;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 12px;
  }

  &:hover {
    color: $blue;

    icon {
      background: $blue;
    }
  }
}

.icon-facebook-o {
  color: $facebook;
}

.button {
  display: inline-block;
  font-family: canada-type-gibson, sans-serif;
  font-weight: 500 !important;
  padding: 15px 42px;
  font-size: 12px !important;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  color: $black;
  text-align: center;

  &.hover {
    background: $sale-green;
    border-color: $sale-green !important;
    box-shadow: 0 1px 15px 0 lightgrey;
    text-decoration: none;
    color: #fff;
  }

  &.default {
    border: 1px solid $black;
    background: transparent;
    color: $black;

    &:hover {
      @extend .hover;

      .icon-facebook-o {
        color: $white;
      }
    }
  }

  &.grey-border {
    border: 1px solid $grey3;
  }

  &.default-blue {
    border: 1px solid $blue;
    color: $blue;
    background: transparent;

    &:hover {
      @extend .hover;
    }
  }

  &.default-dark-blue {
    border: 1px solid $blue-dark;
    color: $blue-dark;
    background: transparent;

    &:hover {
      @extend .hover;

      background: $blue-dark !important;
      border-color: $blue-dark !important;
    }
  }

  &.special {
    border: 1px solid $special;
    color: $special;
    background: transparent;

    &:hover {
      @extend .hover;

      background: $special !important;
      border-color: $special !important;
      box-shadow: 0 1px 15px 0 transparentize($special, 0.8);
    }
  }

  &.blue {
    &:hover {
      @extend .hover;
    }

    color: $white !important;
    background: $blue-dark;
    border-color: $blue-dark;
  }

  &.dark {
    &:hover {
      @extend .hover;
    }

    color: $white !important;
    background: $black;
    border-color: $black;

    &:disabled {
      background: $grey3 !important;
      color: $white !important;
      pointer-events: none;
    }
  }

  &:disabled {
    border: 1px solid $grey3 !important;
    color: $grey3 !important;
    background: #fff !important;

    &:hover {
      border: 1px solid $grey3 !important;
      color: $grey3 !important;
      background: #fff !important;
      box-shadow: none;

      .icon-facebook-o {
        color: $grey3;
      }
    }

    .icon-facebook-o {
      color: $grey3;
    }
  }

  &.block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.small {
    padding: 10px 30px;
    font-size: 12px !important;
  }

  .icon-facebook-o {
    margin-top: -5px;
    vertical-align: middle;
    line-height: inherit;
    font-size: 20px;
    display: inline-block;
  }
}

.layout-row {
  display: flex !important;
  flex-direction: row;

  &.padding {
    & > .flex {
      padding: 0 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.padding-15 {
    & > .flex {
      padding: 0 15px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.padding-30 {
    & > .flex {
      padding: 0 30px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.padding-5 {
    & > .flex {
      padding: 0 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.padding-7 {
    & > .flex {
      padding: 0 7.5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.grid-5 {
    & > .flex {
      padding: 0 5px;
    }
  }

  &.grid-15 {
    & > .flex {
      padding: 0 15px;
    }
  }

  .flex {
    flex: 1;
    min-width: 0;

    &.auto {
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.layout-column {
  display: flex !important;
  flex-direction: column;

  .flex {
    flex: 1;
    min-height: 0;

    &.auto {
      flex: 0 0 auto;
      height: auto;
    }
  }
}

.layout-align-center {
  align-items: center;
}

.layout-space-around {
  justify-content: space-around;
}

.layout-space-evenly {
  justify-content: space-evenly;
}

.layout-justify-center {
  justify-content: center;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-31 {
  margin-top: 31px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-32 {
  margin-top: 32px;
}

.scroll-bar-left {
  height: 100%;

  .ps__scrollbar-y-rail {
    left: 0 !important;
    background: rgb(18 171 233 / 20%) !important;
    border-radius: 3px;
    width: 6px !important;

    .ps__scrollbar-y {
      background: $blue !important;
      right: 0 !important;
      width: 6px !important;
    }
  }
}

.scroll-bar-hidden {
  height: 100%;

  .ps__scrollbar-y-rail {
    opacity: 0 !important;

    .ps__scrollbar-y {
      opacity: 0 !important;
    }
  }
}

ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 15px 30px;

    a {
      font-size: 16px;
      color: $grey1;
      letter-spacing: 0;
      text-decoration: none;
    }

    &.active {
      a {
        color: $black;
      }
    }
  }
}

.icon-button {
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  font-size: 20px;
}

.sale-title {
  color: $special !important;

  .special {
    color: $special;
  }

  @media screen and (max-width: 767px) {
    width: 100% !important;
    margin: auto;
    line-height: 39px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.font-size-16 {
  font-size: 16px;
}

svg-icon {
  display: inline-flex;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.black {
  color: black !important;
}

.red {
  color: $special !important;
}

.grey {
  color: $grey1 !important;
}

@media screen and (max-width: 767px) {
  .mt-10-res {
    margin-top: 10px !important;
    display: block !important;
  }

  .show-full {
    display: none !important;
  }

  .show-res {
    display: block !important;
  }
}

.show-res {
  display: none;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.modal-price {
  font-weight: 500;
  font-size: 26px;
  font-family: Oswald;
  color: $special;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
}

.ends-align {
  margin-top: 40px;
  margin-bottom: 25px;
}

.simple-text {
  font-family: canada-type-gibson !important;
  font-size: 16px !important;
  color: black !important;
  font-weight: 300 !important;
}

@media screen and (min-width: 1200px) {
  .show-md {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .show-md {
    display: none;
  }
}

.special-hover:hover {
  background: $sale-green !important;
}

.special-offer-content {
  padding-top: 10px;
}

.hover:hover {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto !important;
}

.mh400 {
  min-height: 400px;
}

.overflow-hidden {
  overflow-x: hidden !important;
}

@media screen and (max-width: 467px) {
  .section-title {
    margin-top: 90px !important;
  }

  .mt-100-res {
    margin-top: 100px !important;
  }
}

.lightgrey {
  color: #b7b7b7;
}

.lb-text {
  color: inherit;
}

@media screen and (min-width: 467px) {
  .show-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 467px) {
  .show-fullscreen {
    display: none !important;
  }

  .plr-45 {
    padding-right: 15px !important;
  }
}

@media screen and (max-width: 1200px) {
  .show-1200 {
    display: none !important;
  }
}

.button:hover {
  background: $special !important;
}

.step-price {
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 2px;
  border: 1px solid var(--st-color-grey);

  &.only-one {
    height: auto;

    &::ng-deep .price {
      padding: 15px 80px;
    }

    &::ng-deep {
      .sale-badge {
        display: none;
      }
    }
  }

  &.active {
    border-color: var(--st-color-black);
  }
}

.input-wrapper {
  position: relative;

  .st-input {
    padding-right: 60px;
  }

  button {
    border: none;
    background: transparent;
    outline: none;
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -12px;
  }
}

.challenge-description {
  ul {
    list-style-type: disc;
    margin-left: 15px;

    li {
      margin-bottom: 8px;
    }
  }
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

.sale-previous {
  text-decoration: line-through;
  font-size: 28px;
  color: var(--st-color-dark-grey);

  @media screen and (max-width: 992px) {
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
}

.sale-actual {
  color: var(--st-color-alert);

  &--new {
    color: var(--st-color-green);
  }

  .sale-previous {
    text-decoration: line-through;
    font-size: 28px;
    font-weight: 300;
    color: var(--st-color-alert);

    @media screen and (max-width: 992px) {
      font-size: 24px;
    }

    @media screen and (max-width: 500px) {
      font-size: 18px;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.program-landing .cta-width {
  width: 100%;
  flex: none;
  display: block;

  @media (min-width: 600px) {
    max-width: 520px;
  }

  @media (min-width: 960px) {
    max-width: 266px;
  }
}

app-program-landing app-language-selector {
  display: none;
}

